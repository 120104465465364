import { isEmpty } from 'lodash'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { quoteId } from '../cache'
import { MarketingProps } from '../components/MarketingToggles'
import { AdditionalDriverInput, Address, SetAdditionalDrivers, SetProposer } from '../generated/graphql'
import { updateQuoteMutation } from '../hooks'
import useDriverQueries from '../hooks/useDriverQueries'
import { useQuote } from '../hooks/useQuote'
import { ProviderContext } from '../Provider'
import { handleQuoteProgress } from '../utils/handleQuoteProgress'
import { omitObjectKey } from '../utils/omitObjectKey'
import { usePreQuoteContext } from './PageValidator'

type UpdateDrivers = (
  updatedDetails: SetProposer | SetAdditionalDrivers,
  action: 'setProposer' | 'setAdditionalDrivers',
  updatedAddress?: Address | null,
  email?: string | null,
  updatedMarketing?: MarketingProps,
) => Promise<any>

const DriverContext = createContext({
  updatedDriverDetails: {} as SetProposer,
  setUpdatedDriverDetails: {} as (value: SetProposer) => void,
  updatedAdditionalDriver: {} as AdditionalDriverInput,
  setUpdatedAdditionalDriver: {} as (value: AdditionalDriverInput) => void,
  validateAndSave: {} as (step?: string) => boolean,
  isFetching: false,
  updatingDrivers: false,
  lastDriver: false,
  handleUpdateSelectedDriver: {} as () => AdditionalDriverInput[],
  handleDriverEdit: {} as (index: number) => void,
  handleUpdateDrivers: {} as UpdateDrivers,
  additionalDrivers: {} as AdditionalDriverInput[] | [],
  setUpdatingDrivers: (value: boolean) => {},
})

interface DriverPageProviderProps {
  children: ReactNode
}

const DriverPageProvider = ({ children }: DriverPageProviderProps) => {
  const history = useHistory()
  const quote = useQuote()
  const { updateQuote } = updateQuoteMutation()
  const {
    state: { selectedDriverIndex, isFetching },
    setIsFetching,
    handleSelectedDriver,
  } = useContext(ProviderContext)
  const id = quoteId() || ''
  const { loadingDriverData } = useDriverQueries()
  const { validateTransition } = usePreQuoteContext()
  const driver = quote?.details?.proposer || {}
  const additionalDrivers: AdditionalDriverInput[] =
    quote?.details?.additional_drivers?.map(driver => {
      const noClaimsTypename = driver?.claims?.map(claim => {
        return omitObjectKey('__typename', claim)
      })
      const noConvictionsTypename = driver?.convictions?.map(conviction => {
        return omitObjectKey('__typename', conviction)
      })
      return { ...omitObjectKey('__typename', driver), claims: noClaimsTypename, convictions: noConvictionsTypename }
    }) || []
  const drivers = [driver, ...additionalDrivers]
  const lastDriver = drivers?.length - 1 === selectedDriverIndex

  const [updatedDriverDetails, setUpdatedDriverDetails] = useState<SetProposer>({})

  const [updatedAdditionalDriver, setUpdatedAdditionalDriver] = useState<AdditionalDriverInput>({
    claims: [],
    convictions: [],
  })

  const [updatingDrivers, setUpdatingDrivers] = useState(false)

  useEffect(() => {
    setIsFetching(loadingDriverData)
  }, [loadingDriverData])

  useEffect(() => {
    const target = window.location.pathname
    const status = quote?.status?.sections
    const progress = handleQuoteProgress(status, target)
    if (target !== progress && progress) {
      history.push(progress)
    }
  }, [])

  useEffect(() => {
    if (selectedDriverIndex) {
      setUpdatedAdditionalDriver(additionalDrivers[selectedDriverIndex - 1])
      document.getElementById('title')?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [selectedDriverIndex])

  const handleUpdateAddressAction = (parkedAtHome: boolean, updatedAddress: Address) => {
    const defaultAction = { setPolicyAddress: { address: updatedAddress } }
    if (parkedAtHome) {
      return [{ setVehicle: { address: { postcode: updatedAddress.postcode || '' } } }, defaultAction]
    } else {
      return [defaultAction]
    }
  }

  const handleUpdateDrivers: UpdateDrivers = async (
    updatedDetails,
    action,
    updatedAddress,
    email,
    updatedMarketing,
  ) => {
    let actions = [{ [action]: updatedDetails }]

    if (updatedAddress) {
      const updateAddressAction = handleUpdateAddressAction(!!quote?.details?.vehicle.is_parked_home, updatedAddress)
      actions = [...actions, ...updateAddressAction]
    }

    if (email) {
      actions = [...actions, { setEmail: { email } }]
    }

    if (updatedMarketing) {
      await updateQuote({
        variables: {
          id,
          actions: [{ setMarketingPreferences: { ...updatedMarketing } }],
        },
      })
    }

    setIsFetching(true)
    await updateQuote({
      variables: {
        id,
        actions,
      },
    })
    setIsFetching(false)
  }

  const handleUpdateSelectedDriver = () => {
    let updatedDrivers = [...additionalDrivers]
    updatedDrivers[selectedDriverIndex - 1] = updatedAdditionalDriver
    return updatedDrivers
  }

  const handleSaveDriverDetails = async (valid: boolean, step: string) => {
    setUpdatingDrivers(true)
    if (!isEmpty(updatedDriverDetails)) {
      await handleUpdateDrivers(
        updatedDriverDetails,
        'setProposer',
        updatedDriverDetails?.address,
        updatedDriverDetails?.email,
      )
    }
    if (
      selectedDriverIndex &&
      !isEmpty(updatedAdditionalDriver) &&
      JSON.stringify(additionalDrivers[selectedDriverIndex - 1] || []) !== JSON.stringify(updatedAdditionalDriver)
    ) {
      const updatedDrivers = handleUpdateSelectedDriver()
      await handleUpdateDrivers({ additionalDriver: updatedDrivers }, 'setAdditionalDrivers')
    }
    setUpdatingDrivers(false)
    if (step === 'car') {
      history.push('/details/car')
      return
    }
    if (valid) {
      if (lastDriver) {
        handleSelectedDriver(0)
        history.push('/details/cover')
      } else {
        handleSelectedDriver(selectedDriverIndex + 1)
      }
    }
  }

  const validateAndSave = (step?: any) => {
    let valid = true
    if (step !== 'car') {
      valid = validateTransition()
    }
    handleSaveDriverDetails(valid, step)
    return lastDriver ? valid : false
  }

  const handleDriverEdit = async (i: number) => {
    const valid = validateTransition()
    if (!valid) return
    setUpdatingDrivers(true)
    if (!selectedDriverIndex && !isEmpty(updatedDriverDetails)) {
      await handleUpdateDrivers(updatedDriverDetails, 'setProposer')
    }
    if (
      selectedDriverIndex &&
      !isEmpty(updatedAdditionalDriver) &&
      JSON.stringify(additionalDrivers[selectedDriverIndex - 1] || []) !== JSON.stringify(updatedAdditionalDriver)
    ) {
      const updatedDrivers = handleUpdateSelectedDriver()
      await handleUpdateDrivers({ additionalDriver: updatedDrivers }, 'setAdditionalDrivers')
    }
    setUpdatedAdditionalDriver(additionalDrivers[i] || {})
    handleSelectedDriver(i + 1)
    setUpdatingDrivers(false)
  }

  return (
    <DriverContext.Provider
      value={{
        updatedDriverDetails,
        setUpdatedDriverDetails,
        updatedAdditionalDriver,
        setUpdatedAdditionalDriver,
        validateAndSave,
        handleUpdateSelectedDriver,
        handleDriverEdit,
        handleUpdateDrivers,
        additionalDrivers,
        isFetching,
        lastDriver,
        updatingDrivers,
        setUpdatingDrivers,
      }}
    >
      {children}
    </DriverContext.Provider>
  )
}

export const useDriverPageContext = () => useContext(DriverContext)

export default DriverPageProvider
