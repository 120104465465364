import { Sections } from '../generated/graphql'

type ValidPageSatus = {
  car: boolean
  driver: boolean
  cover: boolean
}

const handleProgress = (status: ValidPageSatus, target: string) => {
  const driverPageRoute = '/details/driver'
  const coverPageRoute = '/details/cover'

  const validDriverTransition = target === driverPageRoute && status.car
  const validCoverTransition = target === coverPageRoute && status.car && status.driver

  const allValid = status.car && status.driver && status.cover

  if (validDriverTransition || validCoverTransition || allValid) {
    return target
  }

  return !status.driver ? driverPageRoute : coverPageRoute
}

export const handleQuoteProgress = (quoteStatus: Sections | undefined | null, target: string) => {
  const carPageRoute = '/details/car'
  if (!quoteStatus || target === carPageRoute) return carPageRoute

  const isComplete = (status: string | null | undefined) => status === 'COMPLETE'

  const car = isComplete(quoteStatus.vehicle?.status)
  const driver = isComplete(quoteStatus.proposer?.status) && isComplete(quoteStatus.additionalDriver?.status)
  const cover = isComplete(quoteStatus.policy?.status)

  if (!car) {
    return carPageRoute
  }

  const pageStatus = { car, driver, cover }

  return handleProgress(pageStatus, target)
}
