import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

const { grid } = theme
const { resolution, spacing } = grid

export const RetrievalPageStyle = styled.div`
  * {
    box-sizing: border-box;
    font-family: BM-Biotif, sans-serif;
  }
  .hero-wrapper {
    width: 100%;
    min-height: 250px;
    background: #320b42;
    overflow: hidden;
    @media (min-width: ${resolution.desktop.width}px) {
      min-height: 100vh;
    }

    .desktop-wrapper {
      position: relative;
      @media (min-width: ${resolution.tablet.width}px) {
        min-height: 100vh;
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: 5;
          border-top: 100vh solid transparent;
          border-right: 240px solid ${theme.tints.lightAubergine};
        }
      }

      @media (min-width: ${resolution.desktop.width}px) {
        &:before {
          content: '';
          width: 16vw;
          height: 100%;
          position: absolute;
          right: 0;
          bottom: 0;
          background: ${theme.tints.lightAubergine};
          z-index: 3;
        }
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 150%;
          position: absolute;
          right: 13%;
          top: -70px;
          z-index: 3;
          background: #f4f1f4;
          transform: rotate(11deg);
        }
      }

      @media (min-width: 1600px) {
        &:before {
          content: '';
          width: 32vw;
          height: 100vh;
          position: absolute;
          right: 0;
          top: 0;
          background: ${theme.tints.lightAubergine};
          z-index: 3;
        }
        &:after {
          right: 27%;
          top: -60%;
          z-index: 3;
        }
      }
    }
    .hero-title {
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: -0.41px;
      text-align: center;
      padding-bottom: 32px;
      max-width: 320px;
      position: relative;

      @media (min-width: ${resolution.desktop.width}px) {
        max-width: 400px;
      }
      .line-behind {
        position: relative;
        &:before {
          content: '';
          width: 100%;
          height: 3px;
          position: absolute;
          bottom: 0;
          left: 0;
          background: #f9644b;
          border-radius: 30px;
          z-index: -1;
          right: 0;
          margin: 0 auto;
        }
      }
    }
  }
  .hero-container {
    width: 100%;
    color: #fff;
    position: relative;
    margin: 0 auto;
    z-index: 4;
    @media (min-width: ${resolution.tablet.width}px) {
      width: ${resolution.tablet.width}px;
      padding: 16px;
    }
    @media (min-width: ${resolution.desktop.width}px) {
      width: ${resolution.desktop.width}px;
    }
    .flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .breadcrumbs-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: ${spacing.md}px;
      margin-bottom: ${spacing.md}px;
    }
    .headlines-wrapper {
      .headlines {
        max-width: 250px;
        margin: 0 auto;
        @media (min-width: ${resolution.tablet.width}px) {
          max-width: 100%;
        }
        @media (min-width: ${resolution.desktop.width}px) {
          margin: 0;
        }
        div:last-child {
          margin-right: 0;
        }
      }
    }
    .desktop-form {
      display: none;
      @media (min-width: ${resolution.desktop.width}px) {
        display: block;
      }
      .none-mobile-form {
        min-height: 594px;
        z-index: 10;
        padding: 37px 45px;
        border-radius: 4px;
        @media (min-width: ${resolution.desktop.width}px) {
          width: 622px;
          .input-item {
            &.postcode {
              .form-element {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  .retrieval-container {
    &.mobile {
      @media (min-width: ${resolution.tablet.width}px) {
        display: none;
      }
      .retrieval-form {
        padding: 16px;
        margin: 0;
      }
    }
  }
  .mobile-trustPilot-section {
    position: relative;
    height: 150px; // match height of trustpilot iframe
    width: 100%;
    padding: 24px 16px;
    background: ${theme.primaryColours.aubergine.main};
    @media (min-width: ${resolution.tablet.width}px) {
      display: none;
    }
    &:before {
      content: '';
      width: 24px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: ${theme.tints.lightAubergine};
      z-index: 5;
    }
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      position: absolute;
      right: 24px;
      top: 0;
      z-index: 5;
      border-top: 150px solid transparent;
      border-right: 60px solid ${theme.tints.lightAubergine};
    }
  }
  .tablet-form {
    position: relative;
    display: none;
    width: 100%;
    padding-bottom: 40px;

    @media (min-width: ${resolution.tablet.width}px) {
      display: flex;
      justify-content: center;
    }

    @media (min-width: ${resolution.desktop.width}px) {
      display: none;
    }

    .retrieval-container {
      max-width: 768px;
      height: 100%;
      position: relative;
    }
    .none-mobile-form {
      z-index: 10;
      padding: 37px 48px;
      border-radius: 4px;
      position: relative;
      .sub-title {
        max-width: 330px;
      }
      .input-item {
        &.postcode {
          .form-element {
            margin: 0;
          }
        }
      }
    }
    .trustpilot {
      position: relative;
    }
  }
  .title-headlines {
    margin: 0 auto;
    @media (min-width: ${resolution.desktop.width}px) {
      margin: 0 ${spacing.md}px 0 0;
    }
    .hero-title {
      margin: 0 auto;
      @media (min-width: ${resolution.desktop.width}px) {
        margin: 0;
        text-align: left;
        font-size: 28px;
      }
    }
    .desktop-trustpilot {
      display: none;
      @media (min-width: ${resolution.desktop.width}px) {
        display: block;
        margin-top: ${spacing.xl}px;
      }
    }
  }
  .retrieval-footer {
    width: 100%;
    background: ${theme.primaryColours.paleGray.main};
    .container {
      width: 100%;
      @media (min-width: ${resolution.tablet.width}px) {
        padding: ${spacing.xl}px ${spacing.s}px;
      }
      @media (min-width: ${resolution.desktop.width}px) {
        width: ${resolution.desktop.width}px;
      }
      .links {
        a {
          background: transparent;
          color: ${theme.primaryColours.aubergine.main};
        }
      }
      p {
        color: ${theme.primaryColours.aubergine.main};
      }
    }
  }
  .trustpilot-rating-container {
    width: fit-content;
  }
`
