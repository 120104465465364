import yn from 'yn'
const getEnv = (domains: RegExp) => !!window.location.host.match(domains)

export let isProd = false
export let isTest = false
export let isLocal = false

const prodDomains = /quote.bymiles.co.uk|checkout.bymiles.co.uk/
const testDomains = /quote.test.bymiles.co.uk|checkout.test.bymiles.co.uk|amplifyapp.com/

if (getEnv(prodDomains)) isProd = true
else if (getEnv(testDomains)) isTest = true
else isLocal = true

export const env = (() => {
  if (isProd) return 'prod'
  if (isTest) return 'test'
  return 'local'
})()

const getGtmConfig = () => {
  if (isLocal) {
    return {
      gtmAuth: '6riIIi4F8sU2YUivVPWmjQ',
      gtmPreviewEnv: '111',
    }
  }
  if (isTest) {
    return {
      gtmAuth: 'vMdwefOUK8Ud2C2D5kSVAg',
      gtmPreviewEnv: '7',
    }
  }
  if (isProd) {
    return {
      gtmAuth: 'QwTwCKhcejDtWv7wE7VfNQ',
      gtmPreviewEnv: '2',
    }
  }
}
const getGAConfig = () => {
  if (isLocal) {
    return {
      trackingId: 'UA-84151152-8',
    }
  }
  if (isTest) {
    return {
      trackingId: 'UA-84151152-9',
    }
  }
  if (isProd) {
    return {
      trackingId: 'UA-84151152-1',
    }
  }
}

const getLegacyOrigin = () => {
  if (isLocal) return 'http://localhost:15002'
  if (isTest) return 'https://quote.test.bymiles.co.uk'
  if (isProd) return 'https://quote.bymiles.co.uk'
}

const getCheckoutOrigin = () => {
  if (isLocal) return 'http://localhost:15012'
  if (isTest) return 'https://checkout.test.bymiles.co.uk'
  if (isProd) return 'https://checkout.bymiles.co.uk'
}

const getDashboardOrigin = () => {
  if (isLocal) return 'https://dashboard.test.bymiles.co.uk' // use test env when developing locally
  if (isTest) return 'https://dashboard.test.bymiles.co.uk'
  if (isProd) return 'https://dashboard.bymiles.co.uk'
}

const getBrochureOrigin = (localLooksAtTest = false) => {
  if (isLocal && !localLooksAtTest) return 'http://localhost:15000'
  if (isTest || (isLocal && localLooksAtTest)) return 'https://test.bymiles.co.uk'
  if (isProd) return 'https://bymiles.co.uk'
}

const getQuoteFlowAPIOrigin = () => {
  if (process.env.REACT_APP_QUOTEFLOW_API) return process.env.REACT_APP_QUOTEFLOW_API
  if (isProd) return 'https://checkout.bymiles.co.uk/prod'
  if (isLocal) return '/test'
  return 'https://checkout.test.bymiles.co.uk/test'
}

const getGoogleOptimizeId = (): string => {
  if (isTest) return 'OPT-PBCVM2S'
  if (isProd) return 'OPT-W9S948Z'
  return 'OPT-5FS72P7'
}

const enableMaintenancePage = () => {
  return yn(process.env.UNDER_MAINTENANCE)
}

const config = {
  gtmConfig: getGtmConfig(),
  ga: getGAConfig(),
  zendeskId: '12045655-0618-4c0e-a852-ba7a49499a7d',
  isLocal,
  isProd,
  isTest,
  stripePublicKey: isProd ? 'pk_live_ouaYzmvvlEHnfb1NnPrEc99E' : 'pk_test_DN2dgxurpaKZb3yfqFsXnnKB',
  legacyQuoteOrigin: getLegacyOrigin(),
  checkoutOrigin: getCheckoutOrigin(),
  dashboardOrigin: getDashboardOrigin(),
  getBrochureOrigin: getBrochureOrigin(),
  getBrochureLocalLooksAtTest: getBrochureOrigin(true),
  googleOptimizeId: getGoogleOptimizeId(),
  enableLuckyOrange: true,
  quoteFlowAPIOrigin: getQuoteFlowAPIOrigin(),
  strippedDomain: document.location.hostname.replace(/quote|checkout/, ''),
  christmasTime: false,
  enableCardCheck: !!isProd,
  zendeskChatEnabled: true,
  CXBannerEnabled: false,
  quoteIdCookieName: isProd ? 'quoteId' : 'quoteIdTest',
  quoteTokenCookieName: isProd ? 'quoteToken' : 'quoteTokenTest',
  docs: {
    legalCover: {
      handbook: 'https://static.bymiles.co.uk/docs/legal-handbook.pdf',
      ipid: 'https://static.bymiles.co.uk/docs/legal-ipid.pdf',
    },
    carHire: {
      handbook: 'https://static.bymiles.co.uk/docs/replacement-vehicle-handbook.pdf',
      ipid: 'https://static.bymiles.co.uk/docs/replacement-vehicle-ipid.pdf',
    },
  },
  enableMaintenancePage: enableMaintenancePage(),
}

export default config
