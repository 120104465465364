import { DataLayer } from '@by-miles/data-layer'
import dayjs from 'dayjs'
import Cookie from 'js-cookie'
import ms from 'ms'
import queryString from 'query-string'
import React, { Component, createContext } from 'react'
import { withRouter } from 'react-router'

import config from './config'
import * as providerFunctions from './providerFunctions'
import * as states from './states'
import { checkoutRedirect } from './utils/checkoutRedirect'
import { cookieMonster } from './utils/cookieMonster'
import { legacyRedirect } from './utils/legacyRedirect'
import { LuckyOrange } from './utils/luckyOrange'

export const ProviderContext = createContext()

export class Provider extends Component {
  constructor(props) {
    super(props)
    const mobileUserAgentRegex = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i
    this.quoteValidUntil = dayjs().format('D MMMM YYYY')
    this.queryString = queryString.parse(document.location.search)
    this.state = {
      ...states,
      pageLoading: true,
      showIntercom: false,
      helperIsOpen: false,
      seenModal: false,
      tracking: cookieMonster(),
      quoteId: Cookie.get(config.quoteIdCookieName),
      isMobile: mobileUserAgentRegex.test(navigator.userAgent) || this.queryString.forceMobile,
      showModal: false,
      showHelper: false,
      helperName: '',
      showSticky: false,
      byMilesPhoneNumberFormatted: '0330 088 3838',
      byMilesPhoneNumber: '03300883838',
      isTrackerless: false,
      showVideoBanner: true,
      playVideo: false,
      extrasPopupSeen: false,
      priced: false,
      gaClientIdUpdated: false,
      currentLocation: '',
      ...props.defaultState,
      extras: {
        interactedWithExtras: false,
      },
      trackerConfirmed: false,
      docsConfirmed: false,
      selectedDriverIndex: 0,
      isFetching: false,
      quoteEmail: '',
      difficultPlugginConfirmed: false,
      interval: null,
      obdFit: '',
      confirmPageConfirmed: false,
      confirmPageButtons: {
        policyList: null,
        trackerActivation: null,
      },
      confirmPageErrors: {
        policyList: false,
        trackerActivation: false,
        stickyError: false,
      },
      showHowExperiment: false,
    }
    this.handleShowHelper = this.handleShowHelper.bind(this)

    Object.assign(this, config)

    for (const [key, value] of Object.entries(providerFunctions)) {
      this[key] = value.bind(this)
    }

    this.domain = this.quoteFlowAPIPrefix
    this.postCodeEndPoint = `${this.quoteFlowAPIPrefix}/v1/address`
  }

  componentDidMount() {
    const quoteId = this.queryString.quote_id || Cookie.get(config.quoteIdCookieName)
    this.setState({ quoteId })
    quoteId && LuckyOrange.tag(`${'quote-id ' + quoteId}`, false, true)
    if (!quoteId) return
    window.addEventListener('click', event => {
      if (this.state.showHelper && event.target.tagName === 'DIV') {
        this.setState({ showHelper: false, helperName: '' })
      }
    })

    // this makes sure the modal will be closed when users click the back button in the browser
    window.onpopstate = () => {
      this.setState({ showModal: false })
      document.body.classList.remove('noScroll')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const state = this.state
    if (prevState.quotePricing !== state.quotePricing) {
      this.updatePricing()
    }
    if (prevState.quoteId !== state.quoteId) {
      Cookie.set(config.quoteIdCookieName, state.quoteId, {
        expires: dayjs().add(ms('1 hour'), 'ms').toDate(),
        domain: config.strippedDomain,
      })
    }
    if (state.activeModal !== prevState.activeModal && state.activeModal === 'zero-id-score') {
      this.setState({ showModal: true })
    }

    if (state.showModal !== prevState.showModal) {
      if (state.showModal) {
        document.body.classList.add('noScroll')
      } else {
        document.body.classList.remove('noScroll')
      }
    }
  }

  handleSetInterval = interval => {
    this.setState({ interval })
  }

  handleSelectedDriver = selectedDriverIndex => {
    this.setState({ selectedDriverIndex })
  }

  handleShowModal = activeModal => {
    DataLayer.customEvent({
      eventName: 'open modal',
      section: activeModal,
      eventType: 'form tracking',
    })
    this.setState({ showModal: true, activeModal })
  }

  handleCloseModal = activeModal => {
    DataLayer.customEvent({
      eventName: 'close modal',
      section: activeModal,
      eventType: 'form tracking',
    })
    this.setState({ showModal: false, activeModal: '' })
  }

  setPageLoading = pageLoading => {
    if (pageLoading === this.state.pageLoading) return
    this.setState({ pageLoading })
  }

  setIsFetching = isFetching => {
    this.setState({ isFetching })
  }

  handleShowHelper = helperName => {
    this.setState({ showHelper: true, helperName })
  }

  handleShowInfo = infoName => {
    this.handleShowHelper(this.state.helperName === infoName ? false : infoName)
  }

  redirectLegacyQuote = path => {
    this.setPageLoading(true)
    legacyRedirect(path)
  }

  redirectCheckout = path => {
    this.setPageLoading(true)
    checkoutRedirect(path)
  }

  handleSetConfirmPageButtons = confirmPageButtons => {
    this.setState({ confirmPageButtons })
  }

  handleSetConfirmPageErrors = confirmPageErrors => {
    this.setState({ confirmPageErrors })
  }

  validatePageAndRedirect = (nextButtonUrl, obdFit) => {
    if (
      window.location.pathname === '/how' &&
      (obdFit === 'YesWithCheck' || obdFit === 'YesWithCheckWithCable') &&
      !this.state.difficultPlugginConfirmed &&
      !this.state.isTrackerless
    ) {
      return this.handleShowModal('difficult-plugin')
    }

    if (window.location.pathname === '/cover' && this.state.shouldShowExtrasPopUp) {
      return this.setState({ showModal: true, activeModal: 'extras-popup', extrasPopupSeen: true })
    }
    this.setState({ showModal: false })
    this.props.history.push(nextButtonUrl)
  }

  handleClickedExtras = (type, fromOverlay) => {
    if (!fromOverlay) {
      this.setState({
        extras: {
          ...this.state.extras,
          interactedWithExtras: true,
        },
        shouldShowExtrasPopUp: false,
      })
    }
    this.handleShowModal(type)
  }

  render() {
    return <ProviderContext.Provider value={{ ...this, DataLayer }}>{this.props.children}</ProviderContext.Provider>
  }
}
export default withRouter(Provider)
