import {
  useClaimsQuery,
  useConvictionsQuery,
  useEmploymentQuery,
  useIndustriesQuery,
  useLicenceTypeQuery,
  useOccupationsQuery,
  useRelationshipsQuery,
  useTitlesQuery,
} from '../generated/graphql'

const useDriverQueries = () => {
  const { data: titlesData } = useTitlesQuery()
  const { data: licenceData } = useLicenceTypeQuery()
  const { data: relationshipData } = useRelationshipsQuery()
  const { data: claimsData } = useClaimsQuery()
  const { data: convictionsData } = useConvictionsQuery({ variables: { searchText: '' } })
  const { data: employmentData } = useEmploymentQuery()
  const { data: industriesData } = useIndustriesQuery()
  const { data: occupationsData } = useOccupationsQuery()

  const loadingDriverData =
    !titlesData ||
    !claimsData ||
    !convictionsData ||
    !licenceData ||
    !relationshipData ||
    !employmentData ||
    !industriesData ||
    !occupationsData

  return {
    loadingDriverData,
    titlesData,
    licenceData,
    relationshipData,
    claimsData,
    convictionsData,
    employmentData,
    industriesData,
    occupationsData,
  }
}

export default useDriverQueries
